import React from 'react'
import './career.css'

const CareerFooterSection = () => {
  return (
    <div>
        <div className='career-footer-section-wrapper'>
          
            <div className='career-f-section-col'>      
                  <div className='location'>Posted By:<br /><b>LearnNova Learning Support Centre</b></div>
                  <div style={{color: '#4151E5', marginBottom: '5px'}}><a href='https://www.learnnova.ng' target='blank'><b>www.learnnova.ng</b></a></div>
              </div>
        </div>
    </div>
  )
}

export default CareerFooterSection