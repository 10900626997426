import React from 'react'
import PrivacySection from 'layouts/PolicySections/privacySection'

import '../layouts/PolicySections/policy.css'

const PrivacyPolicyPage = () => {
  return (
    <div className='App'>
        <PrivacySection />
    </div>
    
  )
}

export default PrivacyPolicyPage